html {
    height: 100%;
}

body {
    height: inherit;
}

#root {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    background-color: white;
}